<template>
	<div style="padding-top: 20px">
		<div class="text-center" style="font-size: 22px; font-weight: bold; color: #444;">
			Welcome {{ bages.radiologistName }}

			<div>
				<span style="font-size: 18px;">Review Rate: </span>
				<span class="text-success" v-if="reviewPercent <= 1">Healthy  ({{ reviewPercent }}%)</span>
				<span class="text-warning" v-else-if="reviewPercent <= 2">Average  ({{ reviewPercent }}%)</span>
				<span class="text-danger" v-else>High  ({{ reviewPercent }}%)</span>
			</div>

		</div>
		<div style="height: 20px;"></div>
		<div class="container-fluid">
			<div class="row" style="background: #eee; padding: 15px 0;">
				<div class="col-12" style="font-size: 20px; font-weight: bold;">Today Performance</div>
				<div @click="$router.push('/radiologist/reports/specific')" class="col-sm-6 col-lg-3">
					<div class="card text-white bg-info">
						<div class="card-body">
							<div class="text-value">{{ bages.direct }}</div>
							<div>Number of direct report today</div>
						</div>
					</div>
				</div>
				<div @click="$router.push('/radiologist/reports/pending')" class="col-sm-6 col-lg-3">
					<div class="card text-white bg-primary">
						<div class="card-body">
							<div class="text-value">{{ bages.open }}</div>
							<div>Number of open report today</div>
						</div>
					</div>
				</div>
				<div @click="$router.push('/radiologist/reports/completed')" class="col-sm-6 col-lg-3">
					<div class="card text-white bg-warning">
						<div class="card-body">
							<div class="text-value">{{ bages.completed }}</div>
							<div>Number of completed report today</div>
						</div>
					</div>
				</div>
				<div @click="$router.push('/radiologist/reports/review')" class="col-sm-6 col-lg-3">
					<div class="card text-white bg-danger">
						<div class="card-body">
							<div class="text-value">{{ bages.review }}</div>
							<div>Number of review report today</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" style="margin-top: 10px; background: #eee; padding: 15px 0;">
				<div class="col-12" style="font-size: 20px; font-weight: bold;">This Month Performance</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-info">
						<div class="card-body">
							<div class="text-value">{{ bages.direct_this_month }}</div>
							<div>Direct report this month</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-warning">
						<div class="card-body">
							<div class="text-value">{{ bages.completed_this_month }}</div>
							<div>Completed report this month</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-danger">
						<div class="card-body">
							<div class="text-value">{{ bages.review_this_month }}</div>
							<div>Number of review this month</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-success">
						<div class="card-body">
							<div class="text-value">{{ bages.billing_this_month }}</div>
							<div>Billing amount this month</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" style="margin-top: 10px; background: #eee; padding: 15px 0;">
				<div class="col-12" style="font-size: 20px; font-weight: bold;">Last Month Performance</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-info">
						<div class="card-body">
							<div class="text-value">{{ bages.direct_last_month }}</div>
							<div>Direct report last month</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-warning">
						<div class="card-body">
							<div class="text-value">{{ bages.completed_last_month }}</div>
							<div>Completed report last month</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-danger">
						<div class="card-body">
							<div class="text-value">{{ bages.review_last_month }}</div>
							<div>Number of review last month</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-success">
						<div class="card-body">
							<div class="text-value">{{ bages.billing_last_month }}</div>
							<div>Billing amount last month</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			bages: {},

			reviewPercent: 0,

		}
	},
	async created() {
		this.bages = await this.get("radiologist/dashboard-bages");

		this.reviewPercent = Math.round((this.bages.review_this_month / this.bages.completed_this_month)*100*100)/100;
		
		if(isNaN(this.reviewPercent)) this.reviewPercent = 0;
	}
}
</script>
<style scoped>
.card-body {
	flex: 1 1 auto;
	padding: 1.25rem;
}

.text-value {
	font-size: 1.3125rem;
	font-weight: 600;
}
</style>